import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { Tab, TabsSchema } from '../types'
import { useApiRepository } from './useApiRepository'
import { useLocale } from './useLocale'

interface DataProviderProps {
  entityType: string
  children: ReactNode
}

interface DataContext {
  tabs: Tab[]
  mainWidget: string | undefined
}

const DataContext = createContext<DataContext>({
  tabs: [],
  mainWidget: undefined
})

export const useData = () => useContext(DataContext)

export const DataProvider = ({ children, entityType }: DataProviderProps) => {
  const { api } = useApiRepository()
  const { setExternalLocales } = useLocale()
  const [tabs, setTabs] = useState<Tab[]>([])
  const [mainWidget, setMainWidget] = useState<string | undefined>(undefined)

  const fetchTabs = async () => {
    const { results } = await api.getEntitiesList<TabsSchema>({
      limiter: {
        type: 'EntityTypeMetadata'
      },
      filter: {
        q: [
          {
            key: 'entityType',
            value: entityType,
            operator: '==',
          }
        ]
      }
    })
    setExternalLocales(results[0].locales)
    setMainWidget(results[0].viewTabs.widget)
    setTabs(results[0].viewTabs.items)
  }

  useEffect(() => {
    !tabs.length && fetchTabs()
  }, [tabs])

  return (
    <DataContext.Provider value={{ tabs, mainWidget }}>
      {children}
    </DataContext.Provider>
  )
}
